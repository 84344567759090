import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('section', {params});
    }, 
    show(id) {
        return Service().get('section/show/'+id);
    },    
    create(params) {
        return Service().post('section/create', params);
    },
    update(id, params) {
        return Service().post('section/update/'+id, params);
    },
    delete(id) {
        return Service().post('section/delete/'+id);
    }
}